export const tableCustomStyles = {
  table: {
    style: {
      marginLeft: '16px',
      marginTop: '8px',
      width: '98%'

    }
  },
  rows: {
    style: {
      minHeight: '40px', // Row height

    },
  },
  headRow: {
    style: {
      background: '#e5e5e5',
      minHeight: '40px',
      borderBottom: "#d5d5d5"
    }
  }
}
